.pageHeading{
    background-color: #ea1317eb;
    color: #fff;
}
.logoText{
    color: #ea1317eb;
    font-weight: 900;
}
.filterBtn{
    color: #000;
}
.h4Text{
    font-weight: 700 !important;
}
.h5Text{
    font-weight: 700 !important;
    font-size: 20px !important;
}
.h6Text{
    margin-top: 10px !important;
}
.labelText{
    font-size: 15px !important;
}