body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Saira", sans-serif;
  background: #000000;
}

.numberTextField {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.displayCenterLogo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.displaySpacearound {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.displayAlign {
  display: flex;
  align-items: center;
}

.logoImg {
  width: 120px;
}
